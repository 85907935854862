// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "keycloak\/contains": {
        "original_route": "pt-br\/keycloak\/contains",
        "route": "pt-br\/keycloak\/contains",
        "params": []
    },
    "site\/error": {
        "original_route": "pt-br\/error",
        "route": "pt-br\/error",
        "params": []
    },
    "ser-fine\/show-detail": {
        "original_route": "GET pt-br\/ser\/complaint\/<idSERFine:\\w>\/detail",
        "route": "pt-br\/ser\/complaint\/:idSERFine\/detail",
        "params": [
            "idSERFine"
        ]
    },
    "user\/user\/create": {
        "original_route": "POST pt-br\/user",
        "route": "pt-br\/user",
        "params": []
    },
    "user\/user\/update": {
        "original_route": "PUT pt-br\/user\/<id:\\d+>",
        "route": "pt-br\/user\/:id",
        "params": [
            "id"
        ]
    },
    "user\/user\/update-basic-location": {
        "original_route": "PUT pt-br\/user-basic\/<id:\\d+>",
        "route": "pt-br\/user-basic\/:id",
        "params": [
            "id"
        ]
    },
    "account\/logout": {
        "original_route": "pt-br\/disconnect",
        "route": "pt-br\/disconnect",
        "params": []
    },
    "user\/account\/request-delete-user": {
        "original_route": "PUT pt-br\/remove-user",
        "route": "pt-br\/remove-user",
        "params": []
    },
    "user\/account\/delete": {
        "original_route": "pt-br\/remove-user\/<token>",
        "route": "pt-br\/remove-user\/:token",
        "params": [
            "token"
        ]
    },
    "account\/edit": {
        "original_route": "pt-br\/my-account",
        "route": "pt-br\/my-account",
        "params": []
    },
    "account\/user": {
        "original_route": "pt-br\/my-details",
        "route": "pt-br\/my-details",
        "params": []
    },
    "account\/security": {
        "original_route": "pt-br\/security",
        "route": "pt-br\/security",
        "params": []
    },
    "user\/account\/social-login": {
        "original_route": "pt-br\/account\/social-login\/<social_network_name>",
        "route": "pt-br\/account\/social-login\/:social_network_name",
        "params": [
            "social_network_name"
        ]
    },
    "glovie\/vehicles": {
        "original_route": "pt-br\/my-cars",
        "route": "pt-br\/my-cars",
        "params": []
    },
    "glovie\/vehicles-page": {
        "original_route": "pt-br\/my-cars\/<page:\\d+>",
        "route": "pt-br\/my-cars\/:page",
        "params": [
            "page"
        ]
    },
    "glovie\/trailers": {
        "original_route": "pt-br\/my-trailers",
        "route": "pt-br\/my-trailers",
        "params": []
    },
    "glovie\/trailers-page": {
        "original_route": "pt-br\/my-trailers\/<page:\\d+>",
        "route": "pt-br\/my-trailers\/:page",
        "params": [
            "page"
        ]
    },
    "user\/account\/password-reset": {
        "original_route": "POST pt-br\/recover-password",
        "route": "pt-br\/recover-password",
        "params": []
    },
    "account\/request-password-reset": {
        "original_route": "pt-br\/recover-password",
        "route": "pt-br\/recover-password",
        "params": []
    },
    "user\/account\/reset-password-update": {
        "original_route": "POST pt-br\/change-password\/<token>",
        "route": "pt-br\/change-password\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/reset-password": {
        "original_route": "pt-br\/change-password\/<token>",
        "route": "pt-br\/change-password\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/email-update-request": {
        "original_route": "pt-br\/email-change-request",
        "route": "pt-br\/email-change-request",
        "params": []
    },
    "account\/update-email": {
        "original_route": "pt-br\/change-email\/<token>\/<newEmail>",
        "route": "pt-br\/change-email\/:token\/:newEmail",
        "params": [
            "token",
            "newEmail"
        ]
    },
    "user\/account\/confirm": {
        "original_route": "pt-br\/change-email-confirm\/<code>",
        "route": "pt-br\/change-email-confirm\/:code",
        "params": [
            "code"
        ]
    },
    "account\/login": {
        "original_route": "pt-br\/connect",
        "route": "pt-br\/connect",
        "params": []
    },
    "account\/end-process": {
        "original_route": "pt-br\/end-process",
        "route": "pt-br\/end-process",
        "params": []
    },
    "account\/register": {
        "original_route": "pt-br\/register",
        "route": "pt-br\/register",
        "params": []
    },
    "user\/user\/change-password": {
        "original_route": "pt-br\/change-password",
        "route": "pt-br\/change-password",
        "params": []
    },
    "site\/terms": {
        "original_route": "pt-br\/legal-terms",
        "route": "pt-br\/legal-terms",
        "params": []
    },
    "site\/terms-cookies": {
        "original_route": "pt-br\/privacy-policy-cookies",
        "route": "pt-br\/privacy-policy-cookies",
        "params": []
    },
    "site\/transparency": {
        "original_route": "pt-br\/transpar\u00eancia",
        "route": "pt-br\/transpar\u00eancia",
        "params": []
    },
    "site\/security-policy": {
        "original_route": "pt-br\/politica-seguranca",
        "route": "pt-br\/politica-seguranca",
        "params": []
    },
    "account\/validate": {
        "original_route": "pt-br\/validate\/<token>",
        "route": "pt-br\/validate\/:token",
        "params": [
            "token"
        ]
    },
    "glovie\/pagatelia-order": {
        "original_route": "pt-br\/order\/viat\/<id:\\d+>",
        "route": "pt-br\/order\/viat\/:id",
        "params": [
            "id"
        ]
    },
    "order\/list": {
        "original_route": "pt-br\/orders",
        "route": "pt-br\/orders",
        "params": []
    },
    "order\/page": {
        "original_route": "pt-br\/orders\/<page:\\d+>",
        "route": "pt-br\/orders\/:page",
        "params": [
            "page"
        ]
    },
    "mobe\/list": {
        "original_route": "pt-br\/orders\/viat",
        "route": "pt-br\/orders\/viat",
        "params": []
    },
    "mobe\/page": {
        "original_route": "pt-br\/orders\/viat\/<page:\\d+>",
        "route": "pt-br\/orders\/viat\/:page",
        "params": [
            "page"
        ]
    },
    "toll\/list": {
        "original_route": "pt-br\/orders\/tolls",
        "route": "pt-br\/orders\/tolls",
        "params": []
    },
    "toll\/page": {
        "original_route": "pt-br\/orders\/tolls\/<page:\\d+>",
        "route": "pt-br\/orders\/tolls\/:page",
        "params": [
            "page"
        ]
    },
    "mobe\/update-vehicle": {
        "original_route": "PUT pt-br\/mobe\/<id:\\w*>\/vehicle\/<vehicleId:\\d+>",
        "route": "pt-br\/mobe\/:id\/vehicle\/:vehicleId",
        "params": [
            "id",
            "vehicleId"
        ]
    },
    "mobe\/unbind-vehicle": {
        "original_route": "PUT pt-br\/mobe\/<id:\\w*>\/unbind-vehicle",
        "route": "pt-br\/mobe\/:id\/unbind-vehicle",
        "params": [
            "id"
        ]
    },
    "mobe\/unassign": {
        "original_route": "PUT pt-br\/mobe\/<id:\\w*>\/unassign",
        "route": "pt-br\/mobe\/:id\/unassign",
        "params": [
            "id"
        ]
    },
    "mobe\/search": {
        "original_route": "GET pt-br\/mobe\/search",
        "route": "pt-br\/mobe\/search",
        "params": []
    },
    "mobe\/update-wallet": {
        "original_route": "PUT pt-br\/mobe\/<idMobe:\\w+>\/wallet\/<idWallet:\\d+>",
        "route": "pt-br\/mobe\/:idMobe\/wallet\/:idWallet",
        "params": [
            "idMobe",
            "idWallet"
        ]
    },
    "site\/landing-ora": {
        "original_route": "GET pt-br\/regulated-mobile-parking-payment",
        "route": "pt-br\/regulated-mobile-parking-payment",
        "params": []
    },
    "ser-ticket\/show-detail": {
        "original_route": "GET pt-br\/ticket\/<idSERTicket:\\w+>\/detail",
        "route": "pt-br\/ticket\/:idSERTicket\/detail",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/send-email": {
        "original_route": "GET pt-br\/ticket\/<idSERTicket:\\w+>\/send-email",
        "route": "pt-br\/ticket\/:idSERTicket\/send-email",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/download-pdf": {
        "original_route": "GET pt-br\/ticket\/<idSERTicket:\\w+>.pdf",
        "route": "pt-br\/ticket\/:idSERTicket.pdf",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/pay": {
        "original_route": "pt-br\/park-in-<slug>\/take-ticket",
        "route": "pt-br\/park-in-:slug\/take-ticket",
        "params": [
            "slug"
        ]
    },
    "ser-ticket\/create": {
        "original_route": "POST pt-br\/ticket-be\/get-ticket",
        "route": "pt-br\/ticket-be\/get-ticket",
        "params": []
    },
    "ser-ticket\/unpark": {
        "original_route": "POST pt-br\/ticket\/<id>\/unpark",
        "route": "pt-br\/ticket\/:id\/unpark",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/check-unpark": {
        "original_route": "GET pt-br\/ticket\/<id>\/unpark",
        "route": "pt-br\/ticket\/:id\/unpark",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/new": {
        "original_route": "pt-br\/new\/<id>\/ticket",
        "route": "pt-br\/new\/:id\/ticket",
        "params": [
            "id"
        ]
    },
    "wallet\/payment-method": {
        "original_route": "pt-br\/wallet\/payment-method",
        "route": "pt-br\/wallet\/payment-method",
        "params": []
    },
    "wallet\/my-wallet": {
        "original_route": "pt-br\/wallet",
        "route": "pt-br\/wallet",
        "params": []
    },
    "wallet\/show": {
        "original_route": "pt-br\/wallet\/<idWallet:\\d+>\/show",
        "route": "pt-br\/wallet\/:idWallet\/show",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/data": {
        "original_route": "pt-br\/wallet\/<idWallet:\\d+>\/data",
        "route": "pt-br\/wallet\/:idWallet\/data",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/beneficiaries": {
        "original_route": "pt-br\/wallet\/<idWallet:\\d+>\/beneficiarios",
        "route": "pt-br\/wallet\/:idWallet\/beneficiarios",
        "params": [
            "idWallet"
        ]
    },
    "glovie\/wallet": {
        "original_route": "pt-br\/wallets",
        "route": "pt-br\/wallets",
        "params": []
    },
    "wallet\/pay-debt": {
        "original_route": "PUT pt-br\/wallet\/pay-debt",
        "route": "pt-br\/wallet\/pay-debt",
        "params": []
    },
    "wallet\/create-deposit": {
        "original_route": "POST pt-br\/wallet\/top-up",
        "route": "pt-br\/wallet\/top-up",
        "params": []
    },
    "wallet\/confirm-payment-intent": {
        "original_route": "POST pt-br\/wallet\/confirm-payment-intent",
        "route": "pt-br\/wallet\/confirm-payment-intent",
        "params": []
    },
    "wallet\/associate-credit-card": {
        "original_route": "POST pt-br\/wallet\/<idWallet:\\d+>\/associate-credit-card\/<idCreditCard:\\d+>",
        "route": "pt-br\/wallet\/:idWallet\/associate-credit-card\/:idCreditCard",
        "params": [
            "idWallet",
            "idCreditCard"
        ]
    },
    "invoice\/pdf": {
        "original_route": "GET pt-br\/billing\/invoices\/<invoiceUid>.pdf",
        "route": "pt-br\/billing\/invoices\/:invoiceUid.pdf",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/view-xlsx": {
        "original_route": "GET pt-br\/download-billing\/<invoiceUid>.xlsx",
        "route": "pt-br\/download-billing\/:invoiceUid.xlsx",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/list": {
        "original_route": "GET pt-br\/billing\/invoices",
        "route": "pt-br\/billing\/invoices",
        "params": []
    },
    "invoice\/page": {
        "original_route": "GET pt-br\/billing\/invoices\/<page:\\d+>",
        "route": "pt-br\/billing\/invoices\/:page",
        "params": [
            "page"
        ]
    },
    "invoice\/update": {
        "original_route": "PUT pt-br\/billing\/data",
        "route": "pt-br\/billing\/data",
        "params": []
    },
    "parking\/list": {
        "original_route": "GET pt-br\/search-for-parking-space",
        "route": "pt-br\/search-for-parking-space",
        "params": []
    },
    "parking\/list-aeroparking": {
        "original_route": "GET pt-br\/search-airport-parking",
        "route": "pt-br\/search-airport-parking",
        "params": []
    },
    "parking\/list-season-ticket": {
        "original_route": "GET pt-br\/search-subscription",
        "route": "pt-br\/search-subscription",
        "params": []
    },
    "parking\/search-season-ticket": {
        "original_route": "POST pt-br\/search-subscription",
        "route": "pt-br\/search-subscription",
        "params": []
    },
    "glovie\/list-actives": {
        "original_route": "GET pt-br\/glovie\/list-actives",
        "route": "pt-br\/glovie\/list-actives",
        "params": []
    },
    "glovie\/list-inactives": {
        "original_route": "GET pt-br\/glovie\/list-inactives\/<page:\\d+>",
        "route": "pt-br\/glovie\/list-inactives\/:page",
        "params": [
            "page"
        ]
    },
    "booking\/process": {
        "original_route": "GET pt-br\/confirm-booking\/<idPrebooking:\\d+>",
        "route": "pt-br\/confirm-booking\/:idPrebooking",
        "params": [
            "idPrebooking"
        ]
    },
    "vehicle-inspection-booking\/process": {
        "original_route": "GET pt-br\/confirm-booking-vehicle-inspection",
        "route": "pt-br\/confirm-booking-vehicle-inspection",
        "params": []
    },
    "booking\/show": {
        "original_route": "GET pt-br\/reservation\/<idBooking:\\d+>\/view",
        "route": "pt-br\/reservation\/:idBooking\/view",
        "params": [
            "idBooking"
        ]
    },
    "booking\/new": {
        "original_route": "GET pt-br\/reservation\/<idBooking:\\d+>\/new",
        "route": "pt-br\/reservation\/:idBooking\/new",
        "params": [
            "idBooking"
        ]
    },
    "booking\/cancel": {
        "original_route": "PUT pt-br\/reservation\/cancel\/<idBooking:\\d+>",
        "route": "pt-br\/reservation\/cancel\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "vehicle-brand\/vehicle-models": {
        "original_route": "GET pt-br\/vehicle-brand\/<brandId:\\d+>\/models",
        "route": "pt-br\/vehicle-brand\/:brandId\/models",
        "params": [
            "brandId"
        ]
    },
    "vehicle\/index": {
        "original_route": "GET pt-br\/vehicle",
        "route": "pt-br\/vehicle",
        "params": []
    },
    "vehicle\/view": {
        "original_route": "GET pt-br\/vehicle\/<vehicleid:\\d+>",
        "route": "pt-br\/vehicle\/:vehicleid",
        "params": [
            "vehicleid"
        ]
    },
    "vehicle\/delete": {
        "original_route": "DELETE pt-br\/vehicle\/<vehicleId:\\d+>",
        "route": "pt-br\/vehicle\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "vehicle\/create": {
        "original_route": "POST pt-br\/vehicle",
        "route": "pt-br\/vehicle",
        "params": []
    },
    "vehicle\/update": {
        "original_route": "PUT pt-br\/vehicle\/<vehicleId:\\d+>",
        "route": "pt-br\/vehicle\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "season-ticket\/contract": {
        "original_route": "GET pt-br\/subscription\/<seasonTicketId:\\d+>\/get",
        "route": "pt-br\/subscription\/:seasonTicketId\/get",
        "params": [
            "seasonTicketId"
        ]
    },
    "season-ticket-contract\/show-detail": {
        "original_route": "GET pt-br\/subscription\/payment\/<id:\\d+>\/details",
        "route": "pt-br\/subscription\/payment\/:id\/details",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/show": {
        "original_route": "GET pt-br\/subscription\/payment\/<id:\\d+>\/view",
        "route": "pt-br\/subscription\/payment\/:id\/view",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/new": {
        "original_route": "GET pt-br\/subscription\/payment\/<id:\\d+>\/new",
        "route": "pt-br\/subscription\/payment\/:id\/new",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/create": {
        "original_route": "POST pt-br\/subscription",
        "route": "pt-br\/subscription",
        "params": []
    },
    "season-ticket-contract\/update-vehicle": {
        "original_route": "PUT pt-br\/subscription\/<contractId:\\d+>\/vehicle\/<vehicleId:\\d+>",
        "route": "pt-br\/subscription\/:contractId\/vehicle\/:vehicleId",
        "params": [
            "contractId",
            "vehicleId"
        ]
    },
    "api\/season-ticket-contract\/cancel": {
        "original_route": "DELETE pt-br\/subscription\/<idContract:\\d+>",
        "route": "pt-br\/subscription\/:idContract",
        "params": [
            "idContract"
        ]
    },
    "site\/operators": {
        "original_route": "pt-br\/operators",
        "route": "pt-br\/operators",
        "params": []
    },
    "glovie\/preferences": {
        "original_route": "GET pt-br\/preferences",
        "route": "pt-br\/preferences",
        "params": []
    },
    "user-preferences\/update": {
        "original_route": "PUT pt-br\/preferences\/<id:\\d+>",
        "route": "pt-br\/preferences\/:id",
        "params": [
            "id"
        ]
    },
    "glovie\/privacy": {
        "original_route": "GET pt-br\/privacy",
        "route": "pt-br\/privacy",
        "params": []
    },
    "user\/privacy\/create-update": {
        "original_route": "PUT pt-br\/privacy",
        "route": "pt-br\/privacy",
        "params": []
    },
    "wallet-preferences\/index": {
        "original_route": "GET pt-br\/wallet-preferences",
        "route": "pt-br\/wallet-preferences",
        "params": []
    },
    "wallet-preferences\/update": {
        "original_route": "PUT pt-br\/wallet-preferences\/<id:\\d+>",
        "route": "pt-br\/wallet-preferences\/:id",
        "params": [
            "id"
        ]
    },
    "airport\/show": {
        "original_route": "GET pt-br\/park-in-airport-<slug>\/<id:\\d+>",
        "route": "pt-br\/park-in-airport-:slug\/:id",
        "params": [
            "slug",
            "id"
        ]
    },
    "town\/show": {
        "original_route": "GET pt-br\/park-in-<slug>",
        "route": "pt-br\/park-in-:slug",
        "params": [
            "slug"
        ]
    },
    "ser-zone\/show": {
        "original_route": "GET pt-br\/zone\/<zoneid>\/vehicle\/<vehicleid:\\d+>",
        "route": "pt-br\/zone\/:zoneid\/vehicle\/:vehicleid",
        "params": [
            "zoneid",
            "vehicleid"
        ]
    },
    "glovie\/fines": {
        "original_route": "GET pt-br\/glovie\/fines",
        "route": "pt-br\/glovie\/fines",
        "params": []
    },
    "ser-fine\/check": {
        "original_route": "POST pt-br\/ser-fine\/check",
        "route": "pt-br\/ser-fine\/check",
        "params": []
    },
    "shop\/shop\/invoice": {
        "original_route": "pt-br\/invoice\/<uid>",
        "route": "pt-br\/invoice\/:uid",
        "params": [
            "uid"
        ]
    },
    "user\/account\/unsubscribe-from-commercials": {
        "original_route": "pt-br\/unsubscribe\/<email>",
        "route": "pt-br\/unsubscribe\/:email",
        "params": [
            "email"
        ]
    },
    "viat-shop\/redirect": {
        "original_route": "pt-br\/shop\/products",
        "route": "pt-br\/shop\/products",
        "params": []
    },
    "viat-shop\/detail": {
        "original_route": "pt-br\/shop\/order\/<id>",
        "route": "pt-br\/shop\/order\/:id",
        "params": [
            "id"
        ]
    },
    "viat-shop\/shop": {
        "original_route": "GET pt-br\/shop\/confirm-command\/<id>",
        "route": "pt-br\/shop\/confirm-command\/:id",
        "params": [
            "id"
        ]
    },
    "viat-shop\/new": {
        "original_route": "GET pt-br\/shop\/order\/<id>\/new",
        "route": "pt-br\/shop\/order\/:id\/new",
        "params": [
            "id"
        ]
    },
    "user-preferences\/auto-deposit": {
        "original_route": "pt-br\/auto-deposit",
        "route": "pt-br\/auto-deposit",
        "params": []
    },
    "workshop-booking\/new": {
        "original_route": "pt-br\/workshop-booking\/<idBooking:\\d+>\/new",
        "route": "pt-br\/workshop-booking\/:idBooking\/new",
        "params": [
            "idBooking"
        ]
    },
    "workshop-booking\/selection": {
        "original_route": "pt-br\/book-workshop\/<idProduct:\\d+>",
        "route": "pt-br\/book-workshop\/:idProduct",
        "params": [
            "idProduct"
        ]
    },
    "workshop-booking\/process": {
        "original_route": "pt-br\/confirm-workshop-booking\/<idBooking:\\d+>",
        "route": "pt-br\/confirm-workshop-booking\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "glovie\/show-workshop-booking": {
        "original_route": "pt-br\/workshop-booking\/<idWorkshopBooking:\\d+>\/show",
        "route": "pt-br\/workshop-booking\/:idWorkshopBooking\/show",
        "params": [
            "idWorkshopBooking"
        ]
    },
    "site\/landing-workshop": {
        "original_route": "pt-br\/workshops",
        "route": "pt-br\/workshops",
        "params": []
    },
    "site\/viat": {
        "original_route": "pt-br\/viat",
        "route": "pt-br\/viat",
        "params": []
    },
    "site\/viat-parkings": {
        "original_route": "pt-br\/viat\/parkings",
        "route": "pt-br\/viat\/parkings",
        "params": []
    },
    "site\/viat-portugal": {
        "original_route": "pt-br\/viat\/portugal",
        "route": "pt-br\/viat\/portugal",
        "params": []
    },
    "site\/viat-discounts": {
        "original_route": "pt-br\/viat\/discounts",
        "route": "pt-br\/viat\/discounts",
        "params": []
    },
    "site\/viat-how-it-works": {
        "original_route": "pt-br\/viat\/how-it-works",
        "route": "pt-br\/viat\/how-it-works",
        "params": []
    },
    "site\/landing-how-to-park": {
        "original_route": "pt-br\/how-to-park",
        "route": "pt-br\/how-to-park",
        "params": []
    },
    "site\/landing-how-to-park-airport-parkings": {
        "original_route": "pt-br\/airport-parking",
        "route": "pt-br\/airport-parking",
        "params": []
    },
    "site\/landing-parking-sharing": {
        "original_route": "pt-br\/parking-sharing",
        "route": "pt-br\/parking-sharing",
        "params": []
    },
    "site\/landing-merchant-how-it-works": {
        "original_route": "pt-br\/redeem-coupons",
        "route": "pt-br\/redeem-coupons",
        "params": []
    },
    "site\/landing-merchant": {
        "original_route": "pt-br\/merchant",
        "route": "pt-br\/merchant",
        "params": []
    },
    "site\/landing-enterprise": {
        "original_route": "pt-br\/enterprise",
        "route": "pt-br\/enterprise",
        "params": []
    },
    "merchant\/index": {
        "original_route": "pt-br\/merchant\/account",
        "route": "pt-br\/merchant\/account",
        "params": []
    },
    "merchant\/shop": {
        "original_route": "pt-br\/merchant\/shop",
        "route": "pt-br\/merchant\/shop",
        "params": []
    },
    "merchant\/shop-step": {
        "original_route": "pt-br\/merchant\/shop\/step<id:\\d+>",
        "route": "pt-br\/merchant\/shop\/step:id",
        "params": [
            "id"
        ]
    },
    "coupon-order\/pdf": {
        "original_route": "pt-br\/merchant\/order\/<id:\\d+>.pdf",
        "route": "pt-br\/merchant\/order\/:id.pdf",
        "params": [
            "id"
        ]
    },
    "coupon-order\/render-new-pdf": {
        "original_route": "pt-br\/merchant\/order\/<id:\\d+>\/pdf",
        "route": "pt-br\/merchant\/order\/:id\/pdf",
        "params": [
            "id"
        ]
    },
    "merchant\/order": {
        "original_route": "pt-br\/merchant\/order\/<id:\\d+>\/detail",
        "route": "pt-br\/merchant\/order\/:id\/detail",
        "params": [
            "id"
        ]
    },
    "merchant\/sample-coupon": {
        "original_route": "pt-br\/merchant\/sample-coupon",
        "route": "pt-br\/merchant\/sample-coupon",
        "params": []
    },
    "wallet\/list": {
        "original_route": "GET pt-br\/wallet\/list",
        "route": "pt-br\/wallet\/list",
        "params": []
    },
    "booking\/pay-with-wallet": {
        "original_route": "PUT pt-br\/pay-booking\/<idBooking:\\d+>\/wallet\/<idWallet:\\d+>",
        "route": "pt-br\/pay-booking\/:idBooking\/wallet\/:idWallet",
        "params": [
            "idBooking",
            "idWallet"
        ]
    },
    "season-ticket-contract\/create-and-pay-with-wallet": {
        "original_route": "POST pt-br\/pay-season-ticket-contract\/wallet\/<idWallet:\\d+>",
        "route": "pt-br\/pay-season-ticket-contract\/wallet\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "ser-ticket\/create-and-pay-with-wallet": {
        "original_route": "POST pt-br\/pay-ticket\/wallet\/<idWallet:\\d+>",
        "route": "pt-br\/pay-ticket\/wallet\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "ser-fine\/pay-with-wallet": {
        "original_route": "POST pt-br\/pay-ser-fine\/wallet\/<idWallet:\\d+>",
        "route": "pt-br\/pay-ser-fine\/wallet\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "vehicle-inspection-station\/show": {
        "original_route": "pt-br\/itv\/<slug>\/<id:\\d+>",
        "route": "pt-br\/itv\/:slug\/:id",
        "params": [
            "slug",
            "id"
        ]
    },
    "site\/landing-vehicle-inspection": {
        "original_route": "pt-br\/itv",
        "route": "pt-br\/itv",
        "params": []
    },
    "vehicle-inspection-station\/list": {
        "original_route": "pt-br\/search-vehicle-inspection-station",
        "route": "pt-br\/search-vehicle-inspection-station",
        "params": []
    },
    "glovie\/show-itv-booking": {
        "original_route": "GET pt-br\/vehicle-inspection-booking\/<idBooking:\\d+>\/show",
        "route": "pt-br\/vehicle-inspection-booking\/:idBooking\/show",
        "params": [
            "idBooking"
        ]
    },
    "glovie\/show-rotation": {
        "original_route": "GET pt-br\/rotation\/<idRotation:\\d+>\/show",
        "route": "pt-br\/rotation\/:idRotation\/show",
        "params": [
            "idRotation"
        ]
    },
    "glovie\/show-itv-booking-new": {
        "original_route": "GET pt-br\/vehicle-inspection-booking\/<idBooking:\\d+>\/new",
        "route": "pt-br\/vehicle-inspection-booking\/:idBooking\/new",
        "params": [
            "idBooking"
        ]
    },
    "parking\/show": {
        "original_route": "GET pt-br\/<province>\/<slug>\/<idParking:\\d+>",
        "route": "pt-br\/:province\/:slug\/:idParking",
        "params": [
            "province",
            "slug",
            "idParking"
        ]
    },
    "site\/landing-electric-charge": {
        "original_route": "pt-br\/electric-charge",
        "route": "pt-br\/electric-charge",
        "params": []
    },
    "kiosk\/reset-password": {
        "original_route": "pt-br\/en\/kiosk\/reset-password\/<token>",
        "route": "pt-br\/en\/kiosk\/reset-password\/:token",
        "params": [
            "token"
        ]
    },
    "on-boarding\/redirect": {
        "original_route": "pt-br\/on-boarding-ok",
        "route": "pt-br\/on-boarding-ok",
        "params": []
    },
    "site\/landing-petrol-station": {
        "original_route": "pt-br\/petrol-stations",
        "route": "pt-br\/petrol-stations",
        "params": []
    },
    "site\/landing-parkings": {
        "original_route": "pt-br\/parkings",
        "route": "pt-br\/parkings",
        "params": []
    },
    "poi\/show": {
        "original_route": "pt-br\/park-in-<townSlug>\/parking-<poiSlug>",
        "route": "pt-br\/park-in-:townSlug\/parking-:poiSlug",
        "params": [
            "townSlug",
            "poiSlug"
        ]
    },
    "poi\/show-events": {
        "original_route": "pt-br\/events-in-<poiId>\/<month:\\d+>",
        "route": "pt-br\/events-in-:poiId\/:month",
        "params": [
            "poiId",
            "month"
        ]
    },
    "poi\/show-vehicle-inspection-poi": {
        "original_route": "pt-br\/itv\/itv-booking-<poiSlug>",
        "route": "pt-br\/itv\/itv-booking-:poiSlug",
        "params": [
            "poiSlug"
        ]
    },
    "offered-parking-pass\/contract": {
        "original_route": "GET pt-br\/parking\/<idParking:\\d+>\/parking-pass\/<id:\\d+>",
        "route": "pt-br\/parking\/:idParking\/parking-pass\/:id",
        "params": [
            "idParking",
            "id"
        ]
    },
    "booked-parking-pass\/new": {
        "original_route": "GET pt-br\/parking-pass\/<id:\\d+>\/new",
        "route": "pt-br\/parking-pass\/:id\/new",
        "params": [
            "id"
        ]
    },
    "booked-parking-pass\/show": {
        "original_route": "GET pt-br\/parking-pass\/<id:\\d+>\/show",
        "route": "pt-br\/parking-pass\/:id\/show",
        "params": [
            "id"
        ]
    },
    "mas-movil\/refuse-lot-confirm": {
        "original_route": "POST pt-br\/masmovil\/refuse-lot\/<token>",
        "route": "pt-br\/masmovil\/refuse-lot\/:token",
        "params": [
            "token"
        ]
    },
    "mas-movil\/refuse-lot": {
        "original_route": "pt-br\/masmovil\/refuse-lot\/<token>",
        "route": "pt-br\/masmovil\/refuse-lot\/:token",
        "params": [
            "token"
        ]
    },
    "charging-point\/list": {
        "original_route": "pt-br\/search-electric-chage-station",
        "route": "pt-br\/search-electric-chage-station",
        "params": []
    },
    "glovie\/show-charging-point-booking": {
        "original_route": "GET pt-br\/charging-point-booking\/<idBooking:\\d+>\/show",
        "route": "pt-br\/charging-point-booking\/:idBooking\/show",
        "params": [
            "idBooking"
        ]
    },
    "driver-plus\/landing-fines": {
        "original_route": "pt-br\/driver-plus\/fines-not-notified",
        "route": "pt-br\/driver-plus\/fines-not-notified",
        "params": []
    },
    "driver-plus\/contract": {
        "original_route": "pt-br\/driver-plus\/contract",
        "route": "pt-br\/driver-plus\/contract",
        "params": []
    },
    "glovie\/driver-plus-subscriptions": {
        "original_route": "pt-br\/driver-plus\/subscriptions",
        "route": "pt-br\/driver-plus\/subscriptions",
        "params": []
    },
    "api\/wallet-corporate\/download-file": {
        "original_route": "GET pt-br\/wallets\/<walletId:\\d+>\/download-movements-csv\/<file>",
        "route": "pt-br\/wallets\/:walletId\/download-movements-csv\/:file",
        "params": [
            "walletId",
            "file"
        ]
    },
    "wallet-corporate-plates-whitelist\/download": {
        "original_route": "pt-br\/plates-whitelist\/<walletId:\\d+>\/download",
        "route": "pt-br\/plates-whitelist\/:walletId\/download",
        "params": [
            "walletId"
        ]
    },
    "rental-car\/show": {
        "original_route": "pt-br\/alugar-carros",
        "route": "pt-br\/alugar-carros",
        "params": []
    },
    "rental-car\/contract": {
        "original_route": "pt-br\/terminar-aluguel-carro",
        "route": "pt-br\/terminar-aluguel-carro",
        "params": []
    },
    "rental-car\/detail": {
        "original_route": "pt-br\/alugar-carros\/<id:\\d+>\/detalhe",
        "route": "pt-br\/alugar-carros\/:id\/detalhe",
        "params": [
            "id"
        ]
    },
    "rental-car\/search": {
        "original_route": "pt-br\/alugar-carros\/procurar",
        "route": "pt-br\/alugar-carros\/procurar",
        "params": []
    },
    "site\/index": {
        "original_route": "pt-BR",
        "route": "pt-BR",
        "params": []
    },
    "esp\/sitemap\/lang": {
        "original_route": "pt-BR\/sitemap.xml",
        "route": "pt-BR\/sitemap.xml",
        "params": []
    },
    "site\/home-redirect": {
        "original_route": "esp\/pt-BR",
        "route": "esp\/pt-BR",
        "params": []
    },
    "account\/on-boarding": {
        "original_route": "pt-BR\/complete-profile",
        "route": "pt-BR\/complete-profile",
        "params": []
    }
};